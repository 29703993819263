var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["design-system/v3/components/atoms/image/Image.html.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var t_1;
t_1 = env.getFilter("merge").call(context, env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "classes"),[]),["nh-image"]);
frame.set("classes", t_1, true);
if(frame.topLevel) {
context.setVariable("classes", t_1);
}
if(frame.topLevel) {
context.addExport("classes", t_1);
}
var t_2;
t_2 = env.getFilter("merge").call(context, env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "data"),{}),{"data-loading": "true"});
frame.set("data", t_2, true);
if(frame.topLevel) {
context.setVariable("data", t_2);
}
if(frame.topLevel) {
context.addExport("data", t_2);
}
var t_3;
t_3 = env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "container"),false) == true;
frame.set("use_container", t_3, true);
if(frame.topLevel) {
context.setVariable("use_container", t_3);
}
if(frame.topLevel) {
context.addExport("use_container", t_3);
}
output += "\n\n";
if(runtime.contextOrFrameLookup(context, frame, "use_container") == true) {
output += "\n<div class=\"nh-image-container\">\n";
;
}
output += "\n    <img\n        is=\"nh-image\"\n        class=\"";
output += runtime.suppressValue(env.getFilter("join").call(context, runtime.contextOrFrameLookup(context, frame, "classes")," "), env.opts.autoescape);
output += "\"\n        src=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "src"), env.opts.autoescape);
output += "\"";
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "alt"),"") != "") {
output += "alt=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "alt"), env.opts.autoescape);
output += "\"";
;
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "id"),"") != "") {
output += " id=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "id"), env.opts.autoescape);
output += "\"";
;
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "srcset"),"") != "") {
output += " srcset=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "srcset"), env.opts.autoescape);
output += "\"";
;
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "sizes"),"") != "") {
output += " sizes=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "sizes"), env.opts.autoescape);
output += "\"";
;
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "loading"),"") != "") {
output += " loading=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "loading"), env.opts.autoescape);
output += "\"";
;
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "width"),0) > 0) {
output += " width=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "width"), env.opts.autoescape);
output += "\"";
;
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "height"),0) > 0) {
output += " height=\"";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "height"), env.opts.autoescape);
output += "\"";
;
}
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "objectFit"),"") != "") {
output += " style=\"--nh-image-object-fit: ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "objectFit"), env.opts.autoescape);
output += "\"";
;
}
frame = frame.push();
var t_6 = runtime.contextOrFrameLookup(context, frame, "data");
if(t_6) {t_6 = runtime.fromIterator(t_6);
var t_4;
if(runtime.isArray(t_6)) {
var t_5 = t_6.length;
for(t_4=0; t_4 < t_6.length; t_4++) {
var t_7 = t_6[t_4][0];
frame.set("[object Object]", t_6[t_4][0]);
var t_8 = t_6[t_4][1];
frame.set("[object Object]", t_6[t_4][1]);
frame.set("loop.index", t_4 + 1);
frame.set("loop.index0", t_4);
frame.set("loop.revindex", t_5 - t_4);
frame.set("loop.revindex0", t_5 - t_4 - 1);
frame.set("loop.first", t_4 === 0);
frame.set("loop.last", t_4 === t_5 - 1);
frame.set("loop.length", t_5);
output += " ";
output += runtime.suppressValue(t_7, env.opts.autoescape);
if(t_8) {
output += "=\"";
output += runtime.suppressValue(t_8, env.opts.autoescape);
output += "\"";
;
}
;
}
} else {
t_4 = -1;
var t_5 = runtime.keys(t_6).length;
for(var t_9 in t_6) {
t_4++;
var t_10 = t_6[t_9];
frame.set("name", t_9);
frame.set("value", t_10);
frame.set("loop.index", t_4 + 1);
frame.set("loop.index0", t_4);
frame.set("loop.revindex", t_5 - t_4);
frame.set("loop.revindex0", t_5 - t_4 - 1);
frame.set("loop.first", t_4 === 0);
frame.set("loop.last", t_4 === t_5 - 1);
frame.set("loop.length", t_5);
output += " ";
output += runtime.suppressValue(t_9, env.opts.autoescape);
if(t_10) {
output += "=\"";
output += runtime.suppressValue(t_10, env.opts.autoescape);
output += "\"";
;
}
;
}
}
}
frame = frame.pop();
output += "\n    />\n";
if(runtime.contextOrFrameLookup(context, frame, "use_container") == true) {
output += "\n</div>\n";
;
}
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["design-system/v3/components/atoms/image/Image.html.njk"] , dependencies)